@charset "UTF-8";

textarea {
    height: 200px;
}

.width20 {
    width: 20%;
}

.width50 {
    width: 50%;
}

.width30 {
    width: 30%;
}

.width5 {
    width: 5%;
}

.width65 {
    width: 65%;
}

.width35 {
    width: 35%;
}

header {
    padding-top: 5px;
    background-color:#fff;
}

.main-menu {
    padding-top: 10px;
}

.main-menu > ul > li > a {
    font-weight: 600;
}

.Loader {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    overflow: auto;
    outline: none;
    margin-right: -50%;
    transform: translate(-50%,-50%);
}

.loader-popup {
    position: relative;
    padding: 1px 30px 10px 30px;
    text-align: center;
    background: rgba(0, 0, 0, 0);
    border-radius: 0px;
    border: none;
}

.loader-popup img {
    border-radius: 20px;
    width: 80px;
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    margin-right: -50%;
    transform: translate(-50%,-50%);
}

.Overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 9999999;
}

#count {
    z-index: 0;
}

.ribbon_1 {
    z-index: 0;
}


.form-control.form-white {
    border: 1px solid #000;
    color: #000;
}

.form-control.form-white::-webkit-input-placeholder {
	color: #000;
}
.form-control.form-white:-moz-placeholder {
	opacity: 1;
	color: #000;
}
.form-control.form-white::-moz-placeholder {
	opacity: 1;
	color: #000;
}
.form-control.form-white:-ms-input-placeholder {
	color: #000;
}
.form-control.form-white:focus {
	background: rgba(0,0,0,0.2);
}
.login_icon {
	color:#000;
}

.modal-popup a{
	color:#000;
}
.modal-popup a:hover{
	color:#000;
}
.modal-popup a.close-link {
	color: #000;
}
.modal-popup a.close-link:hover {
	color: #333;
	text-decoration:none;
}

.checkbox-holder span {
    color: #000;
}

footer {
    background-color: #333333;
    color:#ffffff;
}

#social_footer {
    border-top: 1px solid #000;
}

#social_footer p {
    color: #000000;
}

#social_footer ul li a {
    color: #000;
}

header.sticky .main-menu > ul > li > a {
    color: #000000;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.ReactModal__Body--open {
    position:relative;
}

.pac-container {
    z-index: 999999 !important;
}

.pac-item, .pac-item-query {
    padding: 10px;
    font-size: 1.1em;
}

.pac-item:hover {
    background-color: #F7DF4E;
    color: #000;
}

.pac-container:after {
    background-image: none !important;
    height: 0px;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

#tools {
    height: auto;
    padding: 10px;
    font-weight: bold;
}

#tools p {
    padding: 3px;
    margin: -2px;
    font-size: 15px;
}

#cart_box h3 {
    background: #FFFFFF;
    color: #000;
}

.desc .opening {
    color: #2a7100;
}

.error-msg {
    text-align: center;
    padding: 20px;
    background-color: #fff;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 32px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-color: #999;
    border-style: solid;
    border-width: thin;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    border-color: #000;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color:rgb(109, 106, 106);
    border-color: #000;
    border-style: solid;
    border-width: thin;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    vertical-align: middle;
    text-align: center;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {display:none;}
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .switch input:checked + .slider {
    background-color: #2196F3;
  }
  
  .switch input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  .switch input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  ul#cat_nav li a:hover, ul#cat_nav li a.active {
    background: #F7DF4E;
    color: #000000;
  }


#main_menu h3 {
    text-transform: uppercase;
    font-size: 20px;
    border-color: #f9f9f9;
    border-style: solid;
    border-width: thin;
    padding: 10px;
    background-color: #f5f5f5;
    margin-top: 60px;
}

.sub-options {
    padding-left: 15px;
}

a.btn_full.active {
    background-color: #f6d75b;
    color: #000;
}

.radio-container {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

.radio-container:hover input ~ .checkmark {
    background-color: #ccc;
}

.radio-container input:checked ~ .checkmark {
    background-color: #9E9E9E;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-container input:checked ~ .checkmark:after {
    display: block;
}

.radio-container .checkmark:after {
 	top: 5px;
	left: 5px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

a.btn_full.selected {
    font-size: 14px;
    box-shadow: 6px 6px 2px 0px #888;
}

.view-details {
    height: auto;
    padding: 20px;
    border-color: #f1eded;
    border-width: thin;
    border-style: solid;
}

#sub_content h1,
#sub_content_heading h1 {
    color: #ffffff;
}

.react-autosuggest__container {
    position: relative;
    margin-bottom: auto;
  }
  
  .react-autosuggest__input {
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

 .btn_location {
    height: 45px;
    width: 45px;
    position: absolute;
    right: 10px;
    top: 5px;
    border: none;
    background-color: #fff;
    opacity: 0.4;
 }
 .btn_location img{
    width: 100%;
    height: 100%;
    padding: 10px;
 }

 .btn_location:hover,
 .btn_location:focus {
    background-color: whitesmoke;
    opacity: 1;
 }

 #custom-search-error {
    color: #ff0000;
    font-size: 12px;
    padding: 10px;
    background-color: whitesmoke;
    margin-top: 5px;
 }

 #custom-search-input input.btn_search {
     display: none;
 }

 #search-location-popup {
    display: none;
    position: fixed;
    z-index: 999999;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    border: 1px solid #333;
    background-color: #fff;
    width: 45%;
 }

#overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
    background-color: #000;
    z-index: 999999;
}

#sub_content,
#sub_content_heading {
    padding: 20px;
}

#veg-only-filter {
    padding: 20px;
    border: 1px solid #e6e3e3;
    margin-bottom: 10px;
}

#subheader {
    padding-top: 80px;
}

.box_style_2#main_menu {
    /* min-height: 600px; */
    margin-right: 25px;
}

#menu-items-group-parent {  
    overflow: hidden;
}

#menu-items-group {
    height: 600px;
    overflow-x: hidden;
    width: 106%
}

  /* Mobile Device */

  .mobile-cart,
  .mobile-city,
  .mobile-myaccount,
  #mobile_filters_col,
  #close-cart-window,
  #display-mobile-cart,
  #mobile-menu-cat-button,
  #mobile-menu-cat,
  #mobile-pay-now,
  #mobile-back-rest-button,
  #sidebar-logout,
  #disp-mobile-filters,
  #mobile-veg-only-filter {
      display: none;
  }

  @media only screen and (max-width:991px) {

    header {
        position: absolute;
    }

    .mobile-cart {
        display: block;
        position: absolute;
        right: 25px;
        top: 10px;
        font-size: 18px;
    }

    .mobile-myaccount {
        position: absolute;
        font-size: 16px;
        right: 70px;
        top: 10px;
        display: block;
    }

    .mobile-city {
        display: block;
        position: absolute;
        top: 12px;
        right: 100px;
        font-size: 14px;
    }

    .mobile-cart sup {
        background-color: #F7DF4E;
        padding: 2px;
        margin: 1px;
        font-size: 10px;
        border-radius: 3px;
    }

    #filters_col,
    #menu-cat-back-button,
    #menu-cat {
        display: none;
    }

    #mobile_filters_col,
    #mobile-menu-cat-button {
        display: block;
        position: fixed;
        bottom: 20px;
        right: 10px;
        height: 50px;
        width: 50px;
        background-color: #F7DF4E;
        border-radius: 50%;
        z-index: 1;
        border: none;
        box-shadow: 0 0 10px 0px #888;
    }

    #mobile_filters_col div,
    #mobile-menu-cat-button a {
        font-size: 26px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: #000;
    }

    #mobile-filters {
        width: 80%;
        height: 300px;
        margin: 0px auto;
    }

    #child-mobile-filters {
        position: absolute;
        right: -10px;
        width: 100%;
        height: 100%;
        padding: 20px;
        overflow-x: hidden;
    }

    .checkbox-container {
        text-align: left;
    }

    #display-mobile-cart {
        display: none;
        position: absolute;
        top: 56px;
        width: 260px;
        height: auto;
        right: 10px;
        box-shadow: 0px 0px 0px 1px #e0dede;
    }

    #sidebar {
        padding: 0;
        display:none;
    }

    #cart_box a.btn_full, #cart_box .btn_full {
        font-size: 12px;
        width: 60%;
        margin: 5px auto;
    }

    #cart_box { 
        margin-bottom: 0;
    }

    #close-cart-window {
        text-align: center;
        display: block;
        margin: 10px;
        font-size: 22px;
    }
    
    #pointer {
        position: absolute;
        border-right: solid 10px transparent;
        top: -11px;
        right: 24px;
        border-left: solid 10px transparent;
        border-bottom: solid 10px #000;
        width: 0;
        height: 0;
    }

    .dropdown-options .dropdown-menu {
        margin-left: -100px;
        width: 100px;
    }

    .dropdown-options .dropdown-menu:before {
        left: 70%;
    }

    #disp-mobile-menu-cat {
        position: fixed;
        top: 50%;
        left: 50%;
        display: block;
        transform: translateX(-50%) translateY(-50%);
        width: 80%;
        height: auto;
        padding: 0;
        z-index: -1;
    }

    .box_style_1 {
        margin-bottom: 0;
    }

    #menu-cat-overlay,
    #filters-overlay,
    #location-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0.5;
        background-color: #000;
        z-index: 99999;
    }

    #mobile-back-rest-button,
    #sidebar-logout,
    #mobile-pay-now {
        display: block;
    }

    #disp-mobile-filters {
        display: none;
        position: fixed;
        left: 54%;
        top: 50%;
        height: 390px;
        width: 80%;
        background-color: #fff;
        transform: translateX(-50%) translateY(-50%);
        z-index: 999999;
        padding: 10px;
    }

    .btn_location {
        height: 40px;
        width: 40px;
    }

    #search-location-popup {
        width: 400px;
        top:40%;
        left: 50%;
        margin-left: 0px;
    }

    #custom-search-input, #custom-search-input .search-query {
        width: 300px;
    }

    #sub_content,
    #sub_content_heading {
        padding: 0px;
        display: block;
    }
    
    #subheader {
        display: block;
        height: auto;
        padding: 20px;
        padding-top: 60px;
    }

    #mobile-veg-only-filter {
        display: block;
        border: 1px solid #e2e2e2;
        margin: 15px;
    }

    #mobile-veg-only-filter h5 {
        display: inline-block;
    }

    #mobile-veg-filter {
        margin-top: 5px;
        text-align: right;
    }

    .switch {
        width: 50px;
        height: 25px;
    }

    .slider:before {
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 3px;
    }
    
    #close-menu-cat {
        padding: 5px;
        text-align: center;
        font-size: 22px;
        border-top: 1px solid #f3f3f3;
    }
  }

  @media only screen and (max-width:360px) {
    .mobile-cart {
        right: 5px;
        font-size: 15px;
    }

    .mobile-myaccount {
        font-size: 14px;
        right: 40px;
    }

    .mobile-city {
        right: 60px;
        font-size: 12px;
    }

    #pointer {
        right: 0px;
    }

    #menu-cat {
        display: none;
    }

  }