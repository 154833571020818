/*3.1 Preloader*/
#preloader {
	background-color:#F7DF4E; 
}
/*General links color*/
a {color:#333333;}
a:hover,a:focus {color: #111;}

/*-------- Content--------*/
header.sticky{
	background-color: #FFFFFF;
}
.features-content {
    background-color: #F7DF4E;
}
#preloader {
	background-color:#F7DF4E; 
}
.modal-popup {
	background: #FFFFFF;
}
.box_style_2 h2.inner {
	background-color:#FFFFFF; 
	color:#000000; 
}
/*-------- Buttons --------*/
a.btn_1, .btn_1{
	color:#fff;
	background: #000000;
}
a.btn_1:hover, .btn_1:hover{
	background:#333;
}
a.btn_full, .btn_full{
	color:#fff;
	background: #000000;
}
a.btn_full:hover, .btn_full:hover{
	background:#333;
}
a.btn_map{
	color:#fff;
	background:#555;
}
a.btn_map:hover{
	background:#333;
}
a.btn_side{
	color:#fff;
	background:#555;
}
a.btn_side:hover{
	background:#333;
	color:#fff;
}
a.btn_full_outline{
	color:#000000;
	background:none;
	border:2px solid #000000;
}
a.btn_full_outline:hover{
	border:2px solid #333;
	color:#333;
}
a.button_intro, .button_intro  {
	color:#fff !important;
	background: #000000;
}
a.button_intro:hover, .button_intro:hover {
	background: #fff;
	color:#000000 !important;
}
a.button_intro.outilne, .button_intro.outline  {
	border: 2px solid #fff;
	color:#fff;
	background: none;
}
#cart_box a.btn_full, #cart_box .btn_full{
	background: #000000;
}
#cart_box a.btn_full:hover, #cart_box .btn_full:hover{
	background: #333;
}
#cart_box a.btn_full_outline{
	color:#000000;
	background:none;
	border:2px solid #000000;
}
#cart_box a.btn_full_outline:hover{
	color:#333;
	background:none;
	border:2px solid #333;
}
.high_light{
	background:#8560a8;
}

/* All styles MENU 
================================================== */
@media only screen and (min-width: 992px) {

.main-menu ul ul li:hover > a {background-color:#f9f9f9;color:#000000; padding-left:15px;}
	
}

@media only screen and (max-width: 991px) {
	
#header_menu{background-color:#F7DF4E;}

.main-menu ul li a:hover,
a.show-submenu:hover,
a.show-submenu:focus,
a.show-submenu-mega:hover,
a.show-submenu-mega:focus{color:#000000 !important;background-color:#f9f9f9;}	
	
.main-menu ul li  {border-bottom: 1px solid #ededed;color: #fff;}

.main-menu li,
.main-menu a {display: block;color:#555 !important;}

.main-menu a:hover {color:#000000 !important;}
	
}
