/* ====================================================================================================
	MINIMAL CSS3 MENU
==================================================================================================== */

.main-menu {position: relative;z-index:9;width:auto; float:right;margin: 0;padding: 0;}
.main-menu a {-webkit-transition:all 0.3s;-moz-transition:all 0.3s;transition:all 0.3s;}
.main-menu ul,
.main-menu ul li,
.main-menu ul li a {position: relative; margin: 0;padding: 0;}

/* Submenu styles */
.main-menu ul li a {display: block;padding:10px;}
			
/*First level styles */			
.main-menu > ul > li > a {color: #000000; padding: 2px 8px 15px 10px; font-size:14px; margin:0; font-weight:500;}

/* Opacity mask when left open */
.layer {display: none; position: fixed; top: 0; left: 0; width: 100%; min-width: 100%; z-index: 100; min-height: 100%; background-color: #000; opacity: 0; z-index:999;}
.layer-is-visible {display: block;-webkit-animation: fade-in-layer .3s;-moz-animation: fade-in-layer .3s;-ms-animation: fade-in-layer .3s; -o-animation: fade-in-layer .3s;animation: fade-in-layer .3s;opacity: 0.5;}

#close_in,#header_menu, .cmn-toggle-switch{display:none;}	

a.dropdown-toggle.icon-search{display: inline-block;}	

/* IE9 fix that affect the pages with parallax header (ex.single_hotel.html); Issue: submenu disappear when move the cursor to the submenu (only if you did not scroll the page) */
/* .ie9 a.show-submenu, .ie9 a.show-submenu-mega{ background:url(../images/fix_ie_9_menu.png);} */
		
/* All styles for screen size over 992px
================================================== */
@media only screen and (min-width: 992px) {
	
.main-menu {width: auto;}
.main-menu a {white-space: nowrap;}	
.main-menu ul li {display: inline-block;}

.main-menu ul li.submenu:hover > a:before,
.main-menu ul li.submenu:hover > a:after {bottom: -20px;opacity: 0;}
		
/* Submenu */
.main-menu ul ul,
.main-menu ul .menu-wrapper {position: absolute;display: none;z-index: 1;height: auto;left: 3px;top: 100%;margin: 0;padding: 0;background: #fff;min-width:170px;-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);box-shadow: 0 6px 12px rgba(0, 0, 0, .175);}


/* Arrows top */
.main-menu ul ul:before{bottom: 100%;left: 15%;border: solid transparent;content: " ";height: 0;width: 0;position: absolute;pointer-events: none;border-bottom-color: #fff;border-width: 7px;margin-left: -7px;}

.main-menu ul .menu-wrapper ul {-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;border-top: none; margin:0; padding:0;}
.main-menu ul .menu-wrapper ul:before{border:0;}

.main-menu ul li:hover > ul,
.main-menu ul li:hover > .menu-wrapper {display:block;padding: 0;}
.main-menu ul ul li {display: block;height: auto;padding: 0;}		
.main-menu ul ul li a {font-size: 13px;color: #666;border-bottom:1px solid #ededed;display: block;}
.main-menu ul ul li:last-child a {border-bottom:none;}

.main-menu ul ul li:hover > a {background-color:#f9f9f9;color:#dddddd; padding-left:15px;}

.main-menu ul .menu-wrapper ul {position: relative;display:block;}
.main-menu ul .menu-wrapper ul {margin-bottom:15px;left: 0;}

a#search_bt span{ display:none;}
	
}


@media only screen and (max-width: 991px) {
	
#header_menu{text-align:center;padding:25px 15px 25px 15px;position:relative;display:block; background-color:#78cfcf;}

.main-menu ul li a:hover,
a.show-submenu:hover,
a.show-submenu:focus,
a.show-submenu-mega:hover,
a.show-submenu-mega:focus{color:#dddddd !important;background-color:#f9f9f9;}	
	
.main-menu ul li  {border-top: none;border-bottom: 1px solid #ededed;color: #fff;}

/* Menu mobile first level */	
.main-menu ul li a  {padding:10px 15px !important;}

.main-menu h3 {font-size: 12px; line-height:14px;margin: 10px 0 0 0;padding: 0 0 15px 15px;color: #333;text-transform:uppercase;}
.megamenu .menu-wrapper > div {padding:0;}	
	
.main-menu li,
.main-menu a {display: block;color:#555 !important;}
.main-menu li {position: relative;}
	
.main-menu a:hover {color:#dddddd !important;}
	
.main-menu ul > li {padding-bottom: 0;}
.main-menu ul > li i{float:right;}

/* Menu mobile second level */	
.main-menu ul li.submenu ul{font-size: 13px;border-left:1px solid #ededed;margin:0 0 15px 25px;}
.main-menu ul li.submenu ul li{font-size: 13px;border:0;}


/* Menu mobile left panel */	
.main-menu {overflow: auto;left: -100%;bottom: 0;width: 45%;height: 100%;opacity: 0;position:fixed;background-color:#fff;
-webkit-transition:	left 0.8s, opacity 0.5s;
-moz-transition:	left 0.8s, opacity 0.5s;
transition:left 0.8s, opacity 0.5s; z-index:9999;
-webkit-box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
-moz-box-shadow:    1px 0px 5px 0px rgba(50, 50, 50, 0.55);
box-shadow:         1px 0px 5px 0px rgba(50, 50, 50, 0.55);}

.main-menu.show {visibility: visible;left: 0;opacity: 1;}
.main-menu .show-submenu + ul,
a.show-submenu-mega + .menu-wrapper{display: none;visibility: hidden;}
a.show-submenu-mega + .menu-wrapper.show_mega,
.main-menu a.show-submenu + ul.show_normal  {display: block;visibility: visible;}
	
/* Hamburger menu button*/
.cmn-toggle-switch {
  position: relative;
  display:block;
  overflow: visible;
  position:absolute; 
  top:10px;
  right:15px;
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.cmn-toggle-switch:focus {outline: none;}
.cmn-toggle-switch span {display: block;position: absolute;top: 10px;left: 0;right: 0;height: 2px;background: white;}

.cmn-toggle-switch span::before,
.cmn-toggle-switch span::after {position: absolute;display: block;left: 0;width: 100%;height:2px;background-color: #fff; content: "";}

.cmn-toggle-switch span::before {top: -10px;}
.cmn-toggle-switch span::after {bottom: -10px;}

.cmn-toggle-switch__htx span::before,
.cmn-toggle-switch__htx span::after { -webkit-transition-duration: 0.3s, 0.3s;transition-duration: 0.3s, 0.3s;-webkit-transition-delay: 0.3s, 0;transition-delay: 0.3s, 0;}
.cmn-toggle-switch__htx span::before { -webkit-transition-property: top, -webkit-transform;transition-property: top, transform;}
.cmn-toggle-switch__htx span::after {-webkit-transition-property: bottom, -webkit-transform;transition-property: bottom, transform;}

/* active state, i.e. menu open */
.cmn-toggle-switch__htx.active span{background:none !important;}
.cmn-toggle-switch__htx.active span::before {top: 0; -webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}
.cmn-toggle-switch__htx.active span::after {bottom: 0;-webkit-transform: rotate(-45deg); -ms-transform: rotate(-45deg);transform: rotate(-45deg);}
.cmn-toggle-switch__htx.active span::before,
.cmn-toggle-switch__htx.active span::after {-webkit-transition-delay: 0, 0.3s;transition-delay: 0, 0.3s;}

}
@media only screen and (max-width: 767px) {

}	
@media only screen and (max-width: 600px) {
.main-menu {width: 100%;}
a#close_in {display:block;position:absolute;right:15px;top:10px;width:20px;height:20px;}
#close_in i{color:#fff !important;font-size:24px;}

}