h3#pop_msg{
	border-top: 2px solid #ededed;
	border-bottom:2px solid #ededed;
	padding:10px 0;
}
h3#pop_msg strong{
	display:block;
	font-size:36px !important;
}
.pop-container {
  position: fixed;
  text-align: center;
  visibility: visible;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  display: none;
  z-index: 999999;
  margin: 0 0 0 0;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.pop-container *,
.pop-container *:before,
.pop-container *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.pop-container .cleafix:after,
.pop-container.clearfix:after {
  content: " ";
  display: table;
  clear: both;
}
.pop-container.modal {
  pointer-events: auto;
}
.pop-container.shown {
  display: block;
}
.pop-container.top-mid .pop-fixer {
  margin: 0;
}
.pop-container.top-left .flavr-fixer {
  position: fixed;
  margin: 0;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}
.pop-container.top-right .pop-fixer {
  position: fixed;
  margin: 0;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}
.pop-container.bottom-left .pop-fixer {
  position: fixed;
  margin: 0;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}
.pop-container.bottom-right .pop-fixer {
  position: fixed;
  margin: 0;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}
.pop-container.bottom-mid .pop-fixer {
  position: fixed;
  margin: 0;
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  text-align: left;
}
.pop-container.bottom-mid .pop-fixer .pop-outer {
  margin-left: -50%;
  width: 100%;
}
.pop-container.fullscreen .pop-fixer {
  margin-top: 0;
}
.pop-container .pop-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: inherit;
  background: rgba(0, 0, 0, 0.7);
}
.pop-container .pop-fixer {
  position: relative;
  margin: 12% auto 0;
  display: inline-block;
  width: auto;
  pointer-events: auto;
  -webkit-transition: margin 0.3s;
  -moz-transition: margin 0.3s;
  -o-transition: margin 0.3s;
  transition: margin 0.3s;
}
.pop-container .pop-fixer .pop-outer {
  background: #fff;
  min-width: 300px;
  height: auto;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.pop-container .pop-fixer .pop-outer .pop-content {
  font-size: 14px;
  color: #444;
  padding: 20px 30px 20px;
}
.pop-container .pop-fixer .pop-outer .pop-content small{
  color: #999;
  display:block;
}
.pop-container .pop-fixer .pop-outer .pop-content .pop-icon {
  margin-top: 10px;
  margin-bottom: 10px;
}
.pop-container .pop-fixer .pop-outer .pop-content .pop-icon img {
  max-height: 75px;
  max-width: 75px;
}
.pop-container .pop-fixer .pop-outer .pop-content .pop-title {
  margin: 0;
  margin-bottom: 6px;
  font-size: 1.3em;
  line-height: 1.5;
}
.pop-container .pop-fixer .pop-outer .pop-content .pop-message {
  margin-top: 6px;
  color: inherit;
  line-height: 1.5;
}

.pop-container .pop-fixer .pop-outer .flavr-toolbar {
  white-space: nowrap;
  padding: 0 30px 20px;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button {
  outline: none;
  display: block;
  text-decoration: none !important;
  padding: 7px 12px;
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.428571429;
  color: #fff;
  background-color: #795aac;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button:hover,
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button:focus {
  outline: none;
  background-color: #3e4b5d;
  color: #fff;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.primary {
  background-color: #2980b9;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.primary:hover,
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.primary:focus {
  background-color: #3e4b5d;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.success {
  background-color: #27ae60;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.success:hover,
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.success:focus {
  background-color: #3e4b5d;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.info {
  background-color: #1fb5ad;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.info:hover,
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.info:focus {
  background-color: #3e4b5d;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.warning {
  background-color: #f39c12;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.warning:hover,
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.warning:focus {
  background-color: #3e4b5d;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.danger {
  background-color: #f44a56;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.danger:hover,
.pop-container .pop-fixer .pop-outer .pop-toolbar .pop-button.danger:focus {
  background-color: #3e4b5d;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar.inline .pop-button {
  display: inline-block;
  width: auto;
  min-width: 120px;
  margin-left: 6px;
  margin-top: 2px;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar.inline .pop-button:first-child {
  margin-left: 0;
}
.pop-container .pop-fixer .pop-outer .pop-toolbar.stacked .pop-button:first-child {
  margin-top: 0;
}
.pop-container.msie8 .pop-overlay {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiSAMAAG8AaYLq42UAAAAASUVORK5CYII=');
}
.pop-container.msie8 .pop-overlay.opacity-10 {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNikAQAACIAHF/uBd8AAAAASUVORK5CYII=');
}
.pop-container.msie8 .pop-overlay.opacity-20 {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiMAYAADwANpiOMBYAAAAASUVORK5CYII=');
}
.pop-container.msie8 .pop-overlay.opacity-30 {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNi8AEAAFUATydJphIAAAAASUVORK5CYII=');
}
.pop-container.msie8 .pop-overlay.opacity-40 {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiSAMAAG8AaYLq42UAAAAASUVORK5CYII=');
}
.pop-container.msie8 .pop-overlay.opacity-50 {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiqAcAAIgAgoGu+rEAAAAASUVORK5CYII=');
}
.pop-container.msie8 .pop-overlay.opacity-60 {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNimAkAAKIAnNwSwMYAAAAASUVORK5CYII=');
}
.pop-container.msie8 .pop-overlay.opacity-70 {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNi2AQAALsAtXXCpvIAAAAASUVORK5CYII=');
}
.pop-container.msie8 .pop-overlay.opacity-80 {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiOAMAANUAz5n+TlUAAAAASUVORK5CYII=');
}
.pop-container.msie8 .pop-overlay.opacity-90 {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNieAoAAO4A6HfO1f4AAAAASUVORK5CYII=');
}
.pop-container.msie8 .pop-overlay.opacity-100 {
  background-image: none;
  background-color: #000;
}
.pop-container.ie-overlay-false {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6zwAAAgcBApocMXEAAAAASUVORK5CYII=');
}

@media (max-width: 480px) {
.pop-container .pop-fixer .pop-outer .pop-content .pop_img{
	display:none;
}

}
	
